@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  color: aliceblue;
  font-size: xx-large;
}

* {
  touch-action: none;
}